<nav class="navbar smartline-navbar">
  <div class="container-fluid smartline-navbar__flex">
    <div class="row">
      <div class="col-xs-12 col-sm-12">
        <div class="img-container">
          <logo-image *ngIf="empresa" [empresa]="empresa" [idFranquia]="franquia?._id"
            [imgClass]="'img-responsive smartline__cliente-logo'"></logo-image>
        </div>
      </div>
    </div>
  </div>
</nav>

<div class="container-fluid">
  <div class="row">
    <div class="col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
      <h2 class="smartline__heading3">Adicionar Senha</h2>
      <div class="panel panel-default">
        <div class="panel-body">
          <div class="row" *ngIf="!filaSelecionada">
            <div class="col-sm-12 flex flex-wrap align-items-center justify-content-between">
              <ng-container *ngFor="let fila of filas">
                <!-- <button *ngIf="fila.preferencial && fila.visivelTablet" [disabled]="loadingGerarSenha"
                  class="btn btn-primary smartline__button smartline__button--md button-filas" data-toggle="modal"
                  href="#modalNormalPreferencial"
                  [ngStyle]="{'color':empresa?.textColor,'background-color':empresa?.bgColor,'border-color':empresa?.bgColor}"
                  (click)="selecionar(fila)">
                  {{fila.nome}} <img *ngIf="loadingGerarSenha"
                    src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                </button>
    
                <button *ngIf="!fila.preferencial" [disabled]="loadingGerarSenha"
                  class="btn btn-primary smartline__button smartline__button--md button-filas"
                  [ngStyle]="{'color':empresa?.textColor,'background-color':empresa?.bgColor,'border-color':empresa?.bgColor}"
                  (click)="gerarNovaSenha(fila)">
                  {{fila.nome}} <img *ngIf="loadingGerarSenha"
                    src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                </button> -->
                <button [disabled]="loadingGerarSenha"
                  class="btn btn-primary smartline__button smartline__button--md button-filas"
                  [ngStyle]="{'color':empresa?.textColor,'background-color':empresa?.bgColor,'border-color':empresa?.bgColor}"
                  (click)="selecionarFila(fila)">
                  {{fila.nome}} <img *ngIf="loadingGerarSenha"
                    src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                </button>
              </ng-container>
            </div>
          </div>

          <ng-container *ngIf="showNormalPreferencial && !showDadosContatoFila">
            <div class="row">
              <div class="col-sm-12 flex flex-wrap align-items-center justify-content-between">
                <button class="btn btn-primary smartline__button smartline__button--md button-filas3 btn-block"
                  [ngStyle]="{'color':empresa?.textColor,'background-color':empresa?.bgColor,'border-color':empresa?.bgColor}"
                  [disabled]="loading" (click)="selecionarNormalPreferencial(false)">NORMAL</button>

                <button class="btn btn-primary smartline__button smartline__button--md button-filas3 btn-block"
                  [ngStyle]="{'color':empresa?.textColor,'background-color':empresa?.bgColor,'border-color':empresa?.bgColor}"
                  [disabled]="loading" (click)="selecionarNormalPreferencial(true)">PREFERENCIAL</button>
              </div>
            </div>
          </ng-container>

          <div class="row" *ngIf="showDadosContatoFila">
            <div class="col-sm-6">
              <div class="form-group"><label class="smartline__label" for="nome">Nome</label><input type="text"
                  class="form-control smartline__form" placeholder="Nome" id="nome" name="nome" autocomplete="off"
                  [(ngModel)]="nome" required="" value=""></div>
            </div>
            <div class="col-sm-6">
              <div class="form-group"><label class="smartline__label" for="exampleInputEmail1">Telefone</label>
                <input type="text" mask="(00) 00000-0000" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                  placeholder="Seu Telefone" class="form-control smartline__form" name="telefone" id="telefone"
                  [(ngModel)]="numero">
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-group"><label class="smartline__label" for="descricao">Observação</label>
                <textarea class="form-control smartline__form" rows="2" placeholder="Observação..." name="descricao"
                  id="descricao" [(ngModel)]="descricao" autocomplete="off"></textarea>
              </div>
            </div>
            <ng-container *ngIf="showDadosContatoFila">
              <div class="col-xs-12 col-md-6" *ngFor="let item of filaSelecionada.campos; let i = index">
                <div class="form-group">
                  <label>{{item.key}}</label>
                  <input type="text" id="campo_{{i}}" name="campo_{{i}}" placeholder="{{item.key}}"
                    class="form-control smartline__form">
                </div>
              </div>
            </ng-container>
          </div>
          <div class="row">
            <div class="col-sm-12 flex flex-wrap align-items-center justify-content-between">
              <button *ngIf="showDadosContatoFila"
                class="btn btn-primary smartline__button smartline__button--md button-filas2"
                [ngStyle]="{'color':empresa?.textColor,'background-color':empresa?.bgColor,'border-color':empresa?.bgColor}"
                (click)="gerarNovaSenhaMetadata()" [disabled]="loading || !showConfirmarButton">Salvar e
                continuar</button>
              <a href="/atendimento/franquia" class="smartline__button-back2 button-filas2">Cancelar</a>
            </div>
          </div>
        </div>
      </div>
      <h5 class="nome-franquia">{{franquia?.nomeFantasia ?? franquia?.nome}}</h5>
    </div>
  </div>
</div>
