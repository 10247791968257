<nav class="navbar smartline-navbar">
  <div class="container-fluid smartline-navbar__flex">
    <div class="row">
      <div class="col-xs-12 col-sm-12">
        <div class="img-container">
          <logo-image *ngIf="empresa" [empresa]="empresa" [idFranquia]="franquia?._id"
            [imgClass]="'img-responsive smartline__cliente-logo'"></logo-image>
        </div>
      </div>
    </div>
  </div>
</nav>

<div class="flex justify-content-center align-items-center" style="height: calc(100vh - 70px);" *ngIf="!pageLoaded">
  <mat-spinner></mat-spinner>
</div>
<div *ngIf="pageLoaded">
  <div class="smartline-acompanhe" *ngIf="!saiuDaFila">
    <div class="smartline-acompanhe__header"
      [ngStyle]="{'background-color':empresa?.bgColor,'border-color':empresa?.bgColor}">
      <h2 class="smartline-acompanhe__title" [ngStyle]="{'color':empresa?.textColor}">
        <div [ngSwitch]="FALTAM_X_SENHAS">
          <div *ngSwitchCase="1">
            <strong>{{nome ? nome + ', ': ''}}</strong> você é a próxima pessoa da fila!
          </div>
          <div *ngSwitchCase="0">
            <strong>{{nome ? nome + ', ': 'Eba,'}}</strong> chegou sua vez!
          </div>
          <div *ngSwitchDefault><strong>{{nome ? nome + ', ' : ''}}</strong> sua posição é</div>
        </div>
      </h2>
      <ng-template [ngIf]="FALTAM_X_SENHAS > 0" [ngIfElse]="senhaZero">
        <div class="smartline-acompanhe__position" [ngStyle]="{'color':empresa?.textColor}">
          <div class="smartline-acompanhe__fila" [ngStyle]="{'color':empresa?.textColor}">
            <span>{{FALTAM_X_SENHAS}}&#176;</span>
          </div>
        </div>
      </ng-template>
      <ng-template #senhaZero>
        <div *ngIf="senhaSelecionada && FALTAM_X_SENHAS === 0">
          <img [src]="'./../../../assets/img/moving_forward_two_color.svg'" height="250" alt="" />
        </div>
      </ng-template>
      <div [ngSwitch]="FALTAM_X_SENHAS">
        <div *ngSwitchCase="1">
          <p [ngStyle]="{'color':empresa?.textColor}" style="font-size: 2rem;">
            Fique ligado(a) e aguarde!
          </p>
        </div>
        <div *ngSwitchCase="0">
          <p [ngStyle]="{'color':empresa?.textColor}" style="font-size: 2rem;">
            Você está sendo chamado(a)!
          </p>
        </div>
        <div *ngSwitchDefault>
          <p [ngStyle]="{'color':empresa?.textColor}" style="font-size: 2rem;">
            {{(FALTAM_X_SENHAS - 1) === 1 ? 'Falta' : 'Faltam'}} <strong>{{FALTAM_X_SENHAS - 1}}</strong>
            {{(FALTAM_X_SENHAS - 1) === 1 ? 'pessoa' : 'pessoas'}} para sua vez.
          </p>
        </div>
      </div>
    </div>
    <div class="smartline-acompanhe__content">
      <p style="color: '#1e1e1e';">
        {{getProximoFila()}}
      </p>
    </div>
    <div class="smartline-acompanhe__footer">
      <button *ngIf="!(FALTAM_X_SENHAS === 0)" type="button" class="btn btn-danger" data-toggle="modal"
        data-target="#modalSairDaFila">
        <i class="fa fa-sign-out"></i> Sair da Fila
        <img *ngIf="loadingSairDaFila"
          src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
      </button>
      <div class="redesocial-wrapper">
        <div class="redesocial">
          <ng-container *ngFor="let item of empresa?.links">
            <a class="redesocial__item" href="{{item.url}}" target="_blank" rel="noopener noreferrer"
              title="{{item.url}}" [ngStyle]="{'color':empresa?.textColor,'background-color':empresa?.bgColor}">
              <i class="fa {{item.icon}} fa-2x"></i>
            </a>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="smartline-acompanhe" *ngIf="saiuDaFila">
    <div class="smartline-acompanhe__header"
      [ngStyle]="{'background-color':empresa?.bgColor,'border-color':empresa?.bgColor}">
      <h2 class="smartline-acompanhe__title" [ngStyle]="{'color':empresa?.textColor}">
        <div>
          <strong>{{nome ? nome + ', ': 'Que pena,'}}</strong> você saiu da fila!
        </div>
      </h2>
      <img [src]="'./../../../assets/img/moving_forward_two_color.svg'" height="250" alt="" />
      <div>
        <div>
          <p [ngStyle]="{'color':empresa?.textColor}" style="font-size: 2rem;">
            Esperamos você em outra oportunidade!
          </p>
        </div>
      </div>
    </div>

    <div class="smartline-acompanhe__footer">
      <div class="redesocial-wrapper">
        <div class="redesocial">
          <ng-container *ngFor="let item of empresa?.links">
            <a class="redesocial__item" href="{{item.url}}" target="_blank" rel="noopener noreferrer"
              title="{{item.url}}" [ngStyle]="{'color':empresa?.textColor,'background-color':empresa?.bgColor}">
              <i class="fa {{item.icon}} fa-2x"></i>
            </a>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<a (click)="loadHistorico()" #btnLoadHistorico style="display: none;" class="dropdown-item">btnLoadHistorico</a>
<a (click)="playAudio()" #btnPlayAudio style="display: none;" class="dropdown-item">btnPlayAudio</a>
<a (click)="loadSenhaPorIdSenha(idSenha)" #btnLoadSenhaPorIdSenha style="display: none;"
  class="dropdown-item">btnPlayAudio</a>

<div class="modal inmodal fade in" id="modalSairDaFila" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content animated bounceInRight modal-danger">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
            class="sr-only">Close</span></button>
        <h1><i class="fa fa-sign-out"></i></h1>
        <h4 class="modal-title">Sair da Fila</h4>
      </div>
      <div class="modal-body">
        <h2>
          Tem certeza que deseja sair da fila?
        </h2>
        <br />
        <p class="mt-">
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
        <button type="button" class="btn btn-primary mr-10" data-dismiss="modal"
          (click)="sairDaFila()">Continuar</button>
      </div>
    </div>
  </div>
</div>