import { Component, ElementRef, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractComponent, Chamada, Config, Empresa, Fila, Franquia, PublicConfigService, PublicEmpresaService, PublicFranquiaService, PublicSenhaService, Senha, SocketService } from 'lib-smart-core';
import { BehaviorSubject, mergeMap, takeUntil } from 'rxjs';

@Component({
  selector: 'app-acompanhamento-painel',
  templateUrl: './acompanhamento-painel.component.html',
})
export class AcompanhamentoPainelComponent extends AbstractComponent implements OnInit, OnDestroy {

  @ViewChild('btnLoadHistorico') btnLoadHistorico: ElementRef;
  @ViewChild('btnPlayAudio') btnPlayAudio: ElementRef;
  @ViewChild('btnLoadSenhaPorIdSenha') btnLoadSenhaPorIdSenha: ElementRef;
  @ViewChild('btnAtualizarChamada') btnAtualizarChamada: ElementRef;

  codigo: string = '';
  idSenha: string = '';
  idEmpresa: string = '';
  idFranquia: string = '';
  empresa: Empresa;
  franquia: Franquia;
  config: Config;
  filas: Fila[];
  senhaSelecionada: Senha;
  chamadaSelecionada: Chamada;
  filaSelecionada: Fila;
  senha: string = '';
  mesa: string = '';
  FALTAM_X_SENHAS: number;
  audioElementId: string = '';
  nome: string | null;
  pageLoaded: boolean = false;

  // integracao whatsapp
  integrationWhatsApp: boolean = false;

  // sair da fila
  loadingSairDaFila: boolean = false;
  saiuDaFila: boolean = false;

  socket: any;
  socketConnected$: BehaviorSubject<boolean>;

  constructor(
    injector: Injector,
    private socketService: SocketService,
    private empresaService: PublicEmpresaService,
    private configService: PublicConfigService,
    private franquiaService: PublicFranquiaService,
    private senhaService: PublicSenhaService) {
    super(injector);
  }

  ngOnInit() {
    const codigo = super.getParam('codigo');
    this.codigo = codigo;

    const idSenha = super.getParam('idSenha');
    this.idSenha = idSenha;

    this.loadPage();
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.socketService.disconnect();

    document.querySelectorAll('audio').forEach(audio => {
      audio.remove();
    });

    if (this.btnLoadHistorico) {
      this.btnLoadHistorico.nativeElement.removeEventListener('click', this.loadHistorico);
    }
    if (this.btnPlayAudio) {
      this.btnPlayAudio.nativeElement.removeEventListener('click', this.playAudio);
    }
    if (this.btnLoadSenhaPorIdSenha) {
      this.btnLoadSenhaPorIdSenha.nativeElement.removeEventListener('click', () => this.loadSenhaPorIdSenha(this.idSenha));
    }
    if (this.btnAtualizarChamada) {
      this.btnAtualizarChamada.nativeElement.removeEventListener('click', this.loadChamada);
    }
  }

  loadPage() {
    this.senhaService.getById(this.idSenha).pipe(
      mergeMap((senhaSelecionada: Senha) => {
        this.senhaSelecionada = senhaSelecionada
        this.nome = senhaSelecionada.nome?.trim() || null;
        this.saiuDaFila = senhaSelecionada.saiuDaFila;
        return this.franquiaService.getFranquiaPorCodigo(this.codigo);
      }),
      mergeMap((franquia: Franquia) => {
        this.franquia = franquia;
        this.idFranquia = franquia._id;
        this.idEmpresa = franquia.empresa._id
        return this.senhaService.getChamadaPorIdSenha(this.idSenha);
      }),
      mergeMap((chamadaSelecionada: Chamada) => {
        this.chamadaSelecionada = chamadaSelecionada;
        return this.empresaService.getPublic(this.idEmpresa);
      }),
      mergeMap((empresa: Empresa) => {
        this.empresa = empresa;
        return this.configService.getConfigPorFranquia(this.idFranquia)
      }),
      mergeMap((config: Config) => {
        this.config = config;
        return this.senhaService.quantidadeFilaPorSenha(this.idFranquia, this.idSenha);
      }),
    ).subscribe((data) => {
      this.FALTAM_X_SENHAS = data['FALTAM_X_SENHAS'];
      this.loadIntegrationWhatsApp();
      this.configSocket();
      this.pageLoaded = true;
    });
  }

  loadSenhaPorIdSenha = (idSenha) => {
    this.senhaService.getById(idSenha).subscribe(
      (senhaSelecionada: Senha) => {
        this.senhaSelecionada = senhaSelecionada
        this.nome = senhaSelecionada.nome?.trim() || null;
        this.saiuDaFila = senhaSelecionada.saiuDaFila;
        this.loadFranquiaPorCodigo(this.codigo);
        this.loadChamada();
        this.loadIntegrationWhatsApp();
      }
    );
  }

  loadFranquiaPorCodigo = (codigo) => {
    this.franquiaService.getFranquiaPorCodigo(codigo).subscribe(
      (franquia: Franquia) => {
        this.franquia = franquia;
        this.idFranquia = franquia._id;
        this.idEmpresa = franquia.empresa._id
        this.loadEmpresa();
        this.loadConfig();
        this.loadHistorico();
        this.configSocket();
      }
    );
  }

  loadEmpresa = () => {
    this.empresaService.getPublic(this.idEmpresa).subscribe(
      (empresa) => {
        this.empresa = empresa;
      }
    );
  }

  loadConfig = () => {
    this.configService.getConfigPorFranquia(this.idFranquia).subscribe(
      (config) => {
        this.config = config;
      }
    );
  }

  loadHistorico = () => {
    this.senhaService.quantidadeFilaPorSenha(this.idFranquia, this.idSenha).subscribe(
      (data) => {
        this.FALTAM_X_SENHAS = data['FALTAM_X_SENHAS']
      }
    );
  }

  loadChamada = () => {
    this.senhaService.getChamadaPorIdSenha(this.idSenha).subscribe(
      (chamadaSelecionada) => {
        this.chamadaSelecionada = chamadaSelecionada;
      }
    );
  }

  playAudio = async () => {
    if (this.chamadaSelecionada && this.chamadaSelecionada.foiChamado === false) {
      let elementId = "audioElement" + new Date().valueOf().toString();
      let audioElement = document.createElement('audio');
      audioElement.setAttribute("id", elementId);
      audioElement.setAttribute("src", '/assets/audio/audio_default.mpeg');
      audioElement.setAttribute("volume", "0.5");
      document.body.appendChild(audioElement);

      await audioElement.play().then(() => {
        console.log('sucesso');
      }).catch((err) => {
        console.log(err);
      });
    }
  }

  /**
   *
   * socket conection
   */
  configSocket(): void {
    this.socketService.connect();

    const event_senha_ = 'event_senha_' + this.idFranquia;
    this.socketService.listen(event_senha_)
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (data: any) => {
        this.senha = data.senha;
        this.mesa = data.mesa
        this.btnLoadHistorico.nativeElement.click();
        this.btnPlayAudio.nativeElement.click();
      }
    );

    const event_atualizar_senha_ = 'event_atualizar_senha_' + this.idSenha;
    this.socketService.listen(event_atualizar_senha_)
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (data) => {
        this.btnLoadSenhaPorIdSenha && this.btnLoadSenhaPorIdSenha.nativeElement.click();
      }
    );
  }

  loadIntegrationWhatsApp = () => {
    const numero = this.senhaSelecionada.numero;
    if (numero && numero !== '' && numero !== null) {
      this.integrationWhatsApp = true;
    }
  }

  getProximoFila() {
    return (this.FALTAM_X_SENHAS) === 2 ?
      'Esteja preparado(a) e aguarde próximo(a) ao local, pois em breve será a sua vez!' :
      (this.FALTAM_X_SENHAS) === 1 ?
        'Esteja pronto(a) e aguarde no local, pois o atendimento será realizado em breve!' :
        (this.FALTAM_X_SENHAS) === 0 ? 'Dirija-se ao local de atendimento para receber o seu atendimento.' : 'Quando estiver nas primeiras posições da fila, esteja próximo(a) para ser chamado(a).'
  }

  sairDaFila() {
    this.loadingSairDaFila = true;
    this.senhaService.sairDaFila(this.idSenha).subscribe(
      () => {
        this.loadingSairDaFila = false;
        this.saiuDaFila = true;
      },
      (error) => {
        console.error(error);
      },
    );
  }

}


