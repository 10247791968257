import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { RedirectComponent } from './redirect.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule
  ],
  declarations: [
    RedirectComponent,
  ],
  exports: [
    RedirectComponent,
  ],
  providers: [
  ]
})

export class RedirectModule { }
