<nav class="navbar smartline-navbar">
  <div class="container-fluid smartline-navbar__flex">
    <div></div>
    <div class="smartline-navbar__center">
      <a class="" href="/">
        <div class="img-container">
          <logo-image *ngIf="empresa" [empresa]="empresa" [idFranquia]="franquia?._id"
            [imgClass]="'img-responsive smartline__cliente-logo'"></logo-image>
        </div>
      </a>
    </div>

    <div class="smartline-navbar__dropdown smartline-navbar__right navbar-right dropdown">
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" *ngIf="!!currentUser">
        <mat-icon>settings</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item>
          <small>{{currentUser?.usuario.nome}}</small>
        </button>
        <button mat-menu-item>
          <small>{{currentUser?.usuario.email}}</small>
        </button>
        <ng-container *ngIf="!!franquia">
          <mat-divider></mat-divider>
          <button mat-menu-item (click)="generateBannerSmartFilas()" data-toggle="modal" href="#modalBannerSmartPager">
            <mat-icon>qr_code_2</mat-icon>
            <span>QRCode</span>
          </button>
          <button mat-menu-item [routerLink]="['/atendimento']">
            <mat-icon>toggle_off</mat-icon>
            <span>Encerrar Atendimento</span>
          </button>
        </ng-container>
        <mat-divider></mat-divider>
        <button mat-menu-item [routerLink]="['/login']">
          <mat-icon>logout</mat-icon>
          <span>Sair</span>
        </button>
      </mat-menu>

    </div>
  </div>

  <div class="modal inmodal" id="modalBannerSmartPager" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content animated bounceInRight">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
              class="sr-only">Close</span></button>
          <!-- <i class="fa fa-qrcode modal-icon"></i> -->
          <h4 class="modal-title">QRCode SmartPager</h4>
          <h2>{{franquia?.nome}} | {{franquia?.codigo}}</h2>
          <small class="font-bold">
            <a href="{{urlSmartPager}}" target="_blank">{{urlSmartPager}}</a>
          </small>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-7">
              <img alt="image" *ngIf="imgSmartPager !== null" class="rounded image-lg-full" style="width: 100%;" [src]="imgSmartPager">
            </div>
            <div class="col-lg-5">
              <div class="jumbotron">
                <h1>QRCode </h1>
                <p>Este QRCode irá permitir seus clientes entrarem na fila do seu estabelecimento.</p>
                <p><a download="SmartFilas_{{franquia?.codigo}}.png" href="{{imgSmartPager}}" target="_blank"
                    class="btn btn-primary btn-block" role="button"><i class="fa fa-download"></i> Baixar QRCode</a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-white" data-dismiss="modal">Fechar</button>
        </div>
      </div>
    </div>
  </div>

</nav>