import { Component, Injector, OnInit } from '@angular/core';
import { AbstractComponent, Empresa, Franquia, FranquiaService, Mesa, MesaService } from 'lib-smart-core';
import { lastValueFrom } from 'rxjs';
import { Constantes } from '../../../environments/constantes';

@Component({
  selector: 'app-atendimento-home',
  templateUrl: './atendimento-home.component.html',
})
export class AtendimentoHomeComponent extends AbstractComponent implements OnInit {

  idFranquiaSelecionada: string = "";
  idMesaSelecionada: string = "";
  franquias: any[];
  mesas: any[];
  currentUser!: any;
  empresa: Empresa;


  constructor(
    injector: Injector,
    private franquiaService: FranquiaService,
    private mesaService: MesaService) {
    super(injector);
  }

  ngOnInit() {
    this.getUser();
    this.getEmpresa();
    this.empresa = super.getEmpresaSession();
  }

  getUser() {
    let currentUser = JSON.parse(localStorage.getItem(Constantes.currentUser));
    localStorage.removeItem(Constantes.currentFranquia);
    this.currentUser = currentUser;
  }

  getEmpresa() {
    let idEmpresa = super.getIDEmpresaSession();
    if (idEmpresa) {
      if (this.currentUser.usuario.admin === false) {
        let franquias = [];
        franquias.push(...this.currentUser.usuario.franquias);
        this.franquias = franquias;
      } else if (this.currentUser.usuario.manager === true) {
        let franquias = [];
        franquias.push(...this.currentUser.usuario.franquias);
        this.franquias = franquias;
      } else {
        this.getFranquiasPorEmpresa(idEmpresa);
      }
    }
  }

  getFranquiasPorEmpresa = (idEmpresa) => {
    this.franquiaService.getFranquiasPorEmpresa(idEmpresa).subscribe(
      franquias => {
        this.franquias = franquias;
      }
    );
  }

  getMesasPorFranquia = (idFranquia) => {
    this.mesaService.getMesasPorFranquia(idFranquia).subscribe(
      mesas => {
        this.mesas = mesas;
      }
    );
  }

  onChange(event: any) {
    this.getMesasPorFranquia(event.target.value)
  }

  continuar = async () => {
    const franquia: Franquia = await lastValueFrom(this.franquiaService.getById(this.idFranquiaSelecionada));

    if (franquia.bloqueado === true) {
      return;
    }

    const mesa: Mesa = await lastValueFrom(this.mesaService.getById(this.idMesaSelecionada));
    localStorage.setItem(Constantes.currentFranquia, JSON.stringify(franquia));
    localStorage.setItem(Constantes.currentMesa, JSON.stringify(mesa));
    this.router.navigate(['atendimento', 'franquia'])
  }
}
