import { Component, Injector, Input, OnInit } from '@angular/core';
import { AbstractComponent, Empresa, EmpresaService, Franquia, QRCodeTemplate } from 'lib-smart-core';
import QRCode from 'qrcode';
import * as watermark from 'watermarkjs';
import { environment } from '../../../../environments/environment';
import { Constantes } from '../../../../environments/constantes';

@Component({
  selector: 'header',
  templateUrl: 'header.component.html'
})
export class HeaderComponent extends AbstractComponent implements OnInit {

  @Input() empresa!: Empresa;

  franquia: Franquia;
  codigo: string;
  idEmpresa: string;
  currentUser: any;
  imgSmartPager: string;
  urlSmartPager: string;
  showModalQRCode: string;

  constructor(
    injector: Injector,
    private empresaService: EmpresaService) {
    super(injector);
  }

  ngOnInit() {
    let currentUser = super.getCurrentUser();
    if (currentUser) {
      this.currentUser = currentUser;
    }
    const franquia = JSON.parse(localStorage.getItem(Constantes.currentFranquia));
    if (!!franquia) {
      this.franquia = franquia;
    }
  }

  generateBannerSmartFilas() {
    this.imgSmartPager = null;
    this.urlSmartPager = null;

    let qrCodeEspecifico: QRCodeTemplate = null;

    this.empresa && this.empresa.qrCodeTemplates.forEach((qrCodeTemplate: QRCodeTemplate) => {
      qrCodeTemplate['franquias'].forEach((id: string) => {
        if (qrCodeTemplate.type === 'PAGER' && id === this.franquia._id) {
          qrCodeEspecifico = qrCodeTemplate;
        }
      })
    })

    let imageQRCodeEspecifico = qrCodeEspecifico && qrCodeEspecifico.image ?
      qrCodeEspecifico.image : this.empresa.imageTemplatePager ?
        this.empresa.imageTemplatePager : 'https://controle.smartline.com.br/assets/img/SmartQRcode.jpg';

    if (!!imageQRCodeEspecifico) {
      this.empresaService.downloadFromFirebase(imageQRCodeEspecifico).subscribe(
        (arrayBuffer) => {
          let metadata = {
            type: 'image/jpeg'
          };
          const file = new File([new Blob([arrayBuffer])], "SmartPagerQRcode.jpg", metadata);
          const baseUrl = (environment.publicUrl)
          const URL = `${baseUrl}/qrcode/${this.franquia.codigo}`;
          this.urlSmartPager = URL;

          QRCode.toDataURL(URL, { errorCorrectionLevel: 'L', scale: 20 })
            .then(qrcodeData => {
              watermark([file, qrcodeData])
                .image(watermark.image.center())
                .then(img => {
                  this.imgSmartPager = img.src;
                });
            })
            .catch(err => {
              console.error(err)
            });
        }
      );
    }

  }

}
