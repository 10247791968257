import { Component, Injector, OnInit } from '@angular/core';
import { AbstractComponent, AuthenticationService } from 'lib-smart-core';

@Component({
  selector: 'redirect-component',
  templateUrl: 'redirect.component.html',
})
export class RedirectComponent extends AbstractComponent implements OnInit {

  public loading: boolean = false;

  public returnUrl: string;

  constructor(
    private authenticationService: AuthenticationService,
    public injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    const email = super.getQueryParam('email');
    const idEmpresa = super.getQueryParam('idEmpresa');

    this.authenticationService.loginImpersonate(email, idEmpresa).subscribe(
      (payload: any) => {
        this.router.navigate(['atendimento'])
      },
      err => this.alertService.error(err.error.message)
    );
  }

}
