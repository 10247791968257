<header [empresa]="empresa"></header>
<div class="middle-box text-center animated fadeInDown smartline-login">
  <div>
    <h2 class="smartline__heading">Iniciar Atendimento</h2>
    <p class="smartline__text">Selecione o Estabelecimento e Local</p>
    <form class="m-t">
      <div class="form-group">
        <select [(ngModel)]="idFranquiaSelecionada" (change)="onChange($event)"
          class="form-control smartline__select input-lg" id="idFranquiaSelecionada" name="idFranquiaSelecionada"
          required>
          <option value="" checked>Selecione</option>
          <option *ngFor="let f of franquias" [value]="f._id">
            {{f.nome}} {{!!f.nomeFantasia ? ' | ' + f.nomeFantasia : ''}} {{f.bloqueado ? ' [BLOQUEADO]' : '' }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <select [(ngModel)]="idMesaSelecionada" class="form-control smartline__select input-lg" id="idMesaSelecionada"
          name="idMesaSelecionada" required>
          <option value="" checked>Selecione</option>
          <option *ngFor="let m of mesas" [ngValue]="m._id">{{m.nome}} - {{m.descricao}}</option>
        </select>
      </div>
      <button type="submit"
        (click)="continuar()"
        class="btn btn-primary block full-width m-b smartline__button smartline__button--md">Iniciar</button>
    </form>
  </div>
</div>
