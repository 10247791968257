import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService, AuthenticationService } from 'lib-smart-core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent {

  email: string;
  senha: string;
  loading: boolean;
  returnUrl: string = '';
  submitted: boolean = false;

  constructor(
    private router: Router,
    private alertService: AlertService,
    private authenticationService: AuthenticationService) {
  }

  ngOnInit() {
    this.authenticationService.logout();
  }

  login() {
    this.authenticationService.login(this.email, this.senha).subscribe({
      next: () => {
        this.router.navigate(['atendimento'])
      },
      error: err => {
        console.log('err', JSON.stringify(err));
        console.log('err', JSON.stringify(err));
        this.alertService.error(err.error.message);
      }
    });
  }
}
