import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AlertModule, AuthGuard, CONSTANTES, ENV, JwtService, customHttpProvider } from 'lib-smart-core';
import { ToastrModule } from 'ngx-toastr';
import { SharedModule } from '../../../lib-smart-core/src/public-api';
import { Constantes } from '../environments/constantes';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AcompanhamentoPainelComponent } from './modules/acompanhamento-painel/acompanhamento-painel.component';
import { AtendimentoHomeComponent } from './modules/atendimento-home/atendimento-home.component';
import { AtendimentoNovaSenhaComponent } from './modules/atendimento-nova-senha/atendimento-nova-senha.component';
import { AtendimentoPainelComponent } from './modules/atendimento-painel/atendimento-painel.component';
import { AtendimentoQrcodeFilasComponent } from './modules/atendimento-qrcode-filas/atendimento-qrcode-filas.component';
import { AtendimentoQrcodeFormComponent } from './modules/atendimento-qrcode-form/atendimento-qrcode-form.component';
import { HeaderComponent } from './modules/layout/header/header.component';
import { LoginComponent } from './modules/login/login.component';
import { RedirectModule } from './modules/redirect/redirect.module';
import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AtendimentoHomeComponent,
    AtendimentoPainelComponent,
    AtendimentoNovaSenhaComponent,
    AtendimentoQrcodeFilasComponent,
    AtendimentoQrcodeFormComponent,
    HeaderComponent,
    AcompanhamentoPainelComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    RedirectModule,
    AlertModule,
    ToastrModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    AuthGuard,
    customHttpProvider,
    { provide: HTTP_INTERCEPTORS, useClass: JwtService, multi: true },
    { provide: ENV, useValue: environment },
    { provide: CONSTANTES, useValue: Constantes },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
