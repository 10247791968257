<header [empresa]="empresa"></header>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 panel-senhas">

      <!--Senha Chamada-->
      <div *ngIf="senhaSelecionada && senhaSelecionada.senha" class="alert alert-warning alert-dismissible alert-senha">
        <div class="flex justify-content-between align-items-center">
          <strong>Senha: {{senhaSelecionada.senha}}{{senhaSelecionada.nome && (' | ' + senhaSelecionada.nome)}}</strong>
          <span>
            <button class="btn btn-sm btn-primary smartline__button mr-5" (click)="rechamarSenha()"
              [disabled]="loadingChamar || loadingRechamar" (click)="selecionarSenhaEncaminhar(senhaSelecionada)"
              [ngStyle]="{'color':empresa?.textColor,'background-color':empresa?.bgColor,'border-color':empresa?.bgColor}"><i
                class="glyphicon glyphicon-refresh"></i>
            </button>
            <button type="button" class="btn btn-sm btn-primary smartline__button" title="Encaminhar"
              (click)="selecionarSenhaEncaminhar(senhaSelecionada)" [disabled]="loadingChamar || loadingRechamar"
              data-toggle="modal" data-target="#modalEncaminhaSenha"
              [ngStyle]="{'color':empresa?.textColor,'background-color':empresa?.bgColor,'border-color':empresa?.bgColor}"><i
                class="glyphicon glyphicon-share"></i>
            </button>
          </span>
        </div>
      </div>

      <a (click)="atualizarListaChamada()" #btnAtualizarChamada style="display: none;" class="dropdown-item">ATUALIZA
        LISTA
        CHAMADA</a>

      <!--Filas-->
      <ul class="nav nav-tabs smartline-tabs">
        <li role="presentation" class="smartline-tabs__tab flex justify-content-center align-items-center"
          *ngFor="let fila of filas" [ngClass]="{'active': (filaSelecionada && filaSelecionada?._id === fila._id)}"
          (click)="carregarChamadaPorFila(fila._id)">
          <a href="javascript:void(0)" class="smartline-tabs__link">
            <div class="text-fila flex justify-content-center align-items-center">{{fila.nome}}
              <span class="badge smartline__badge-blue ml-2"
                [ngStyle]="{'color':empresa?.textColor,'background-color':empresa?.bgColor,'border-color':empresa?.bgColor}">{{getTotalPorFila(fila._id)}}</span>
            </div>
          </a>
        </li>
        <li role="presentation" class="smartline-tabs__tab flex justify-content-center align-items-center"
          (click)="carregarChamadaSenhaDiarioGeralPager(franquia._id)" [ngClass]="{'active': viewGeral === true}">
          <a href="javascript:void(0)" class="smartline-tabs__link">Geral</a>
        </li>
        <li role="presentation" class="smartline-tabs__tab flex justify-content-center align-items-center"
          (click)="carregarHistoricoFranquia(franquia._id)" [ngClass]="{'active': viewHistorico === true}">
          <a href="javascript:void(0)" class="smartline-tabs__link">Histórico</a>
        </li>
      </ul>

      <!--Lista de Senhas-->
      <div class="panel panel-default panel-senhas">

        <!--Lista vazia!-->
        <div *ngIf="pager.list.length === 0 && empresa" class="listEmpty"><img
            src="./../../../assets/img/rocket-launch-9978.svg" height="100" alt="">
          <h3>Fila vazia! Clique em “Adicionar senha” para incluir novos clientes.</h3><a class="btn btn-primary"
            [routerLink]="['/filas', franquia.codigo]"
            [ngStyle]="{'color':empresa?.textColor,'background-color':empresa?.bgColor,'border-color':empresa?.bgColor}">Adicionar
            Senha</a>
        </div>

        <!--Filas visão mobile-->
        <ng-container *ngIf="pager.list.length > 0">
          <div class="panel panel-default hidden-desktop" *ngFor="let chamada of pager.list; let i = index;"
            style="margin: 15px">
            <div class="panel-body">
              <div class="ibox-content text-center" style="margin: 0; border: 0">
                <h1>{{ (pager.page === 1 ? i + 1 : '') }}
                  {{ (pager.page === 2 ? i + 1 + pager.perPage : '') }}
                  {{ (pager.page > 2 ? ((pager.perPage * (pager.page-1)) + i + 1) : '' ) }}º - <a href="#!"
                    data-toggle="modal" data-target="#modalQrcodeTitulo"
                    (click)="selecionarSenhaNaChamadaQRCode(chamada.senha);">{{chamada.senha.senha}}</a>
                </h1>
                <p class="font-bold">{{(chamada.foiChamado === true) ? (chamada.dataChamada | date : 'HH:mm:ss') :
                  (chamada.data | date: 'HH:mm:ss')}}</p>
                <p>{{chamada.senha.nome}}</p>
                <p *ngIf="chamada.senha.numero">
                  <span *ngIf="chamada.senha.permiteWhatsApp" class="fa fa-whatsapp fa-lg mr-5"></span>
                  <a [href]="'tel:+chamada.senha.numero'">{{chamada.senha.numero}}</a>
                </p>
                <p *ngIf="chamada.senha.email">
                  <span class="fa fa-at fa-lg mr-5"></span>{{chamada.senha.email}}
                </p>
                <p *ngIf="chamada.senha.campos">
                  <span *ngFor="let campo of chamada.senha.campos">
                    <b>{{campo.key}}:</b> {{campo.value}}
                  </span>
                </p>
                <p *ngIf="chamada.senha.descricao">{{chamada.senha.descricao}}</p>
                <ng-container *ngIf="viewHistorico">
                  <p>
                    <span class="label label-danger" style="margin-right: 2px;" *ngIf="chamada.senha.saiuDaFila">SAIU DA
                      FILA</span>
                    <span class="label label-success" style="margin-right: 2px;"
                      *ngIf="!chamada.senha.saiuDaFila && chamada.senha.compareceu">COMPARECEU</span>
                    <span class="label label-warning" style="margin-right: 2px;"
                      *ngIf="!chamada.senha.saiuDaFila  && !chamada.senha.compareceu">NÃO COMPARECEU</span>
                  </p>
                </ng-container>
                <div class="text-center">

                  <button type="button" (click)="selecionarSenhaEdicao(chamada.senha);"
                    class="btn btn-primary smartline__button mr-10"
                    [ngStyle]="{'color':empresa?.textColor,'background-color':empresa?.bgColor,'border-color':empresa?.bgColor}"
                    data-toggle="modal" data-target="#modalEdicaoSenha" title="Editar Senha">
                    <i class="glyphicon glyphicon-edit"></i>
                  </button>

                  <button type="button" *ngIf="chamada.senha.numero" (click)="selecionarSenhaMensagem(chamada.senha);"
                    class="btn btn-primary smartline__button mr-10"
                    [ngStyle]="{'color':empresa?.textColor,'background-color':empresa?.bgColor,'border-color':empresa?.bgColor}"
                    data-toggle="modal" data-target="#modalEnviarMensagem" title="Enviar Mensagem">
                    <i class="glyphicon glyphicon-comment"></i>
                  </button>

                  <button type="button" *ngIf="!viewHistorico" (click)="selecionarSenhaEncaminhar(chamada.senha);"
                    data-toggle="modal" data-target="#modalEncaminhaSenha"
                    class="btn btn-primary smartline__button mr-10"
                    [ngStyle]="{'color':empresa?.textColor,'background-color':empresa?.bgColor,'border-color':empresa?.bgColor}"
                    title="Encaminhar">
                    <i class="glyphicon glyphicon-share"></i>
                  </button>

                  <button type="button" (click)="selecionarSenhaNaChamada(chamada.senha)"
                    class="btn btn-primary smartline__button mr-10" data-toggle="modal"
                    data-target="#modalSenhaJaChamada"
                    [ngStyle]="{'color':empresa?.textColor,'background-color':empresa?.bgColor,'border-color':empresa?.bgColor}"
                    title="Chamar Manual">
                    <i class="glyphicon glyphicon-volume-up"></i>
                  </button>

                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <!-- Filas tabelas -->
        <div class="table-responsive hidden-moba" *ngIf="pager.list.length > 0">
          <table class="table">
            <thead>
              <tr>
                <th *ngIf="!viewHistorico && !viewGeral" class="text-center">#</th>
                <th class="text-center">Senha/Nome</th>
                <th class="text-center">Hora</th>
                <th *ngIf="(viewHistorico || viewGeral)" class="text-center">Fila</th>
                <th class="text-center">Número</th>
                <th class="text-left">Descrição</th>
                <th class="text-left">Campos</th>
                <th class="text-left" *ngIf="viewHistorico">Comparecimento</th>
                <th width="40%" class="text-right">Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let chamada of pager.list; let i = index;">
                <td *ngIf="!viewHistorico && !viewGeral" class="text-center">
                  {{ (pager.page === 1 ? i + 1 : '') }}
                  {{ (pager.page === 2 ? i + 1 + pager.perPage : '') }}
                  {{ (pager.page > 2 ? ((pager.perPage * (pager.page-1)) + i + 1) : '' ) }}
                </td>
                <td class="text-center">
                  <a href="#!" data-toggle="modal" data-target="#modalQrcodeTitulo"
                    (click)="selecionarSenhaNaChamadaQRCode(chamada.senha)">{{chamada.senha.senha}}</a>
                  {{!!chamada.senha.nome ? '| ' + chamada.senha.nome : ''}}
                </td>
                <td class="text-center">{{(chamada.foiChamado === true) ? (chamada.dataChamada | date : 'HH:mm:ss') :
                  (chamada.data | date: 'HH:mm:ss')}}
                </td>
                <td *ngIf="(viewHistorico || viewGeral)" class="text-center">{{chamada.fila.nome}}</td>
                <td class="text-center">
                  <span *ngIf="chamada.senha.numero && chamada.senha.permiteWhatsApp"
                    class="fa fa-whatsapp fa-lg mr-5"></span>
                  <a [href]="'tel:+chamada.senha.numero'">{{chamada.senha.numero}}</a>
                </td>
                <td class="text-left">{{chamada.senha.descricao}}</td>
                <td class="text-left">
                  <span *ngIf="chamada.senha.email"><b>Email: </b>{{chamada.senha.email}}<br></span>
                  <span *ngFor="let campo of chamada.senha.campos">
                    <b>{{campo.key}}:</b> {{campo.value}}<br>
                  </span>
                </td>
                <td class="text-left" *ngIf="viewHistorico">
                  <span class="label label-danger" style="margin-right: 2px;" *ngIf="chamada.senha.saiuDaFila">SAIU DA
                    FILA</span>
                  <span class="label label-success" style="margin-right: 2px;"
                    *ngIf="!chamada.senha.saiuDaFila && chamada.senha.compareceu">COMPARECEU</span>
                  <span class="label label-warning" style="margin-right: 2px;"
                    *ngIf="!chamada.senha.saiuDaFila  && !chamada.senha.compareceu">NÃO COMPARECEU</span>
                </td>
                <td class="text-right">
                  <div>
                    <button type="button" (click)="selecionarSenhaEdicao(chamada.senha)"
                      class="btn btn-primary smartline__button mr-5"
                      [ngStyle]="{'color':empresa?.textColor,'background-color':empresa?.bgColor,'border-color':empresa?.bgColor}"
                      data-toggle="modal" data-target="#modalEdicaoSenha" title="Editar Senha">
                      <i class="glyphicon glyphicon-edit"></i>
                    </button>

                    <button *ngIf="chamada.senha.numero" type="button" (click)="selecionarSenhaMensagem(chamada.senha)"
                      class="btn btn-primary smartline__button mr-5"
                      [ngStyle]="{'color':empresa?.textColor,'background-color':empresa?.bgColor,'border-color':empresa?.bgColor}"
                      data-toggle="modal" data-target="#modalEnviarMensagem" title="Enviar Mensagem">
                      <i class="glyphicon glyphicon-comment"></i>
                    </button>

                    <button *ngIf="!viewHistorico" type="button" (click)="selecionarSenhaEncaminhar(chamada.senha)"
                      data-toggle="modal" data-target="#modalEncaminhaSenha"
                      class="btn btn-primary smartline__button mr-5"
                      [ngStyle]="{'color':empresa?.textColor,'background-color':empresa?.bgColor,'border-color':empresa?.bgColor}"
                      title="Encaminhar">
                      <i class="glyphicon glyphicon-share"></i>
                    </button>

                    <button type="button" (click)="selecionarSenhaNaChamada(chamada.senha)" data-toggle="modal"
                      data-target="#modalSenhaJaChamada"
                      class="btn btn-primary smartline__button flex-inline align-items-center"
                      [ngStyle]="{'color':empresa?.textColor,'background-color':empresa?.bgColor,'border-color':empresa?.bgColor}"
                      title="Chamar Manual">
                      <i class="glyphicon glyphicon-volume-up"></i> <span class='ml-2'>Chamar</span>
                    </button>
                  </div>
                </td>

              </tr>
            </tbody>
          </table>
        </div>
        <div class="text-center" *ngIf="(pager.total > pager.perPage)">
          <div class="text-center" *ngIf="pager.total > pager.perPage">
            <paginator class="pagination" [pager]="pager" (onPager)="loadPage($event)"></paginator>
          </div>
        </div>

      </div>
    </div>
    <h5 class="nome-franquia">{{franquia?.nomeFantasia ?? franquia?.nome}}</h5>

    <!-- Adicionar Senha-->
    <a class="btn btn-success btn-circle btn-lg btn-fly" [routerLink]="['/filas', franquia.codigo]"><span
        class="glyphicon glyphicon-plus"></span></a>
  </div>
</div>

<!--Chamar senha-->
<div *ngIf="empresa && filaSelecionada && pager.total > 0"
  class="smartline-actions flex justify-content-center align-items-center flex-direction-column">
  <button class="btn btn-md btn-primary smartline__button smartline__button--md button-filas2"
    [disabled]="loadingChamar || loadingRechamar" (click)="chamarProximaSenha()"
    [ngStyle]="{'color':empresa?.textColor,'background-color':empresa?.bgColor,'border-color':empresa?.bgColor}">
    Chamar próxima senha
  </button>
</div>
<button style="display: none; opacity: 0; visibility: hidden;" #btnShowModalExibirSenha data-toggle="modal"
  data-target="#modalModalExibirSenha">
</button>

<!--Modal QRCode-->
<div class="modal inmodal fade in" id="modalQrcodeTitulo" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content animated bounceInRight">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
            class="sr-only">Close</span></button>
        <i class="fa fa-tablet modal-icon"></i>
        <h4 class="modal-title">QRCode de {{senhaSelecionadaChamada ? senhaSelecionadaChamada?.senha : ''}}</h4>
        <p>Chame a senha {{senhaSelecionadaChamada ? senhaSelecionadaChamada?.senha : ''}} presente na fila
          <code>{{filaSelecionada ? filaSelecionada?.nome : ''}}</code>
        </p>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12 text-center">
            <canvas id="canvas" align="center"></canvas>
            <p>
              <br /><a href="{{urlSenhaSelecionadaChamada}}" target="_blank"
                rel="noopener noreferrer">{{urlSenhaSelecionadaChamada}}</a>
            </p>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-white" data-dismiss="modal">Fechar</button>
      </div>
    </div>
  </div>
</div>

<!--Modal SenhaJaChamada-->
<div class="modal inmodal fade in" id="modalSenhaJaChamada" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content animated bounceInRight">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
            class="sr-only">Close</span></button>
        <h1><i class="glyphicon glyphicon-volume-up"></i></h1>
        <h4 class="modal-title">Chamar senha</h4>
        <p>Chame a senha <strong>{{senhaSelecionadaChamada ? senhaSelecionadaChamada.senha : ''}}</strong> presente na
          fila
          <code>{{senhaSelecionadaChamada?.fila ? senhaSelecionadaChamada?.fila.nome : ''}}</code>
        </p>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12 text-center">
            <p>
              Tem certeza que deseja chamar a senha {{senhaSelecionadaChamada ? senhaSelecionadaChamada?.senha : ''}}
            </p>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-white" data-dismiss="modal">Fechar</button>
        <button type="button" *ngIf="empresa" class="btn btn-primary smartline__button" data-dismiss="modal"
          [ngStyle]="{'color':empresa?.textColor,'background-color':empresa?.bgColor,'border-color':empresa?.bgColor}"
          (click)="chamarSenhaNaChamada(); ">Chamar
        </button>
      </div>
    </div>
  </div>
</div>

<!--Modal EncaminhaSenha-->
<div class="modal inmodal fade in" id="modalEncaminhaSenha" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content animated bounceInRight">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
            class="sr-only">Close</span></button>
        <h1><i class="glyphicon glyphicon-share"></i></h1>
        <h4 class="modal-title">Encaminhar</h4>
        <p>A senha <strong>{{senhaSelecionadaEncaminhar ? senhaSelecionadaEncaminhar?.senha : ''}}</strong> pode ser
          transferida para qualquer fila abaixo.
        </p>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <table class="table table-hover">
              <tbody>
                <tr *ngFor="let fila of filas">
                  <td class="project-title">
                    <a href="#!">{{fila?.nome}}</a>
                    <br />
                    <small>{{fila?.sigla}} | {{fila?.prefixo}}</small>
                  </td>
                  <td class="project-actions">
                    <button
                      *ngIf="senhaSelecionadaEncaminhar?.fila && senhaSelecionadaEncaminhar?.fila._id === fila._id"
                      readonly disabled="true" class="btn btn-primary smartline__button btn-sm"
                      data-dismiss="modal">Encaminhar</button>
                    <button
                      *ngIf="empresa && !(senhaSelecionadaEncaminhar?.fila && senhaSelecionadaEncaminhar?.fila._id === fila._id)"
                      (click)="encaminhar(fila._id)" class="btn btn-primary smartline__button btn-sm"
                      [ngStyle]="{'color':empresa?.textColor,'background-color':empresa?.bgColor,'border-color':empresa?.bgColor}"
                      data-dismiss="modal">Encaminhar</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Modal EdicaoSenha-->
<div class="modal inmodal fade in" id="modalEdicaoSenha" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content animated bounceInRight">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
            class="sr-only">Close</span></button>
        <h1><i class="glyphicon glyphicon-edit"></i></h1>
        <h4 class="modal-title">Editar senha</h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Senha</label>
              <input type="text" readonly disabled class="form-control" name="senha" id="senha"
                [(ngModel)]="senhaSelecionadaEdicao.senha" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Data</label>
              <input type="text" readonly disabled class="form-control" name="data" id="data"
                [value]="senhaSelecionadaEdicao?.data | date: 'dd/MM/yyyy HH:mm:ss'" />
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>Nome</label>
          <input type="text" placeholder="Insira o Nome" class="form-control" name="nome" id="nome" [(ngModel)]="nome">
        </div>
        <div class="form-group">
          <label>Telefone</label>
          <input type="text" mask="(00) 00000-0000" [showMaskTyped]="true" [dropSpecialCharacters]="false"
            placeholder="Insira o Telefone" class="form-control" name="telefone" id="telefone" [(ngModel)]="numero">
        </div>
        <div class="form-group">
          <label>Descrição</label>
          <input type="text" placeholder="Insira a descrição" class="form-control" name="descricao" id="descricao"
            [(ngModel)]="descricao">
        </div>
        <div class="row" *ngIf="senhaSelecionadaEdicao.campos">
          <div class="col-md-6" *ngFor="let item of senhaSelecionadaEdicao.campos; let i = index">
            <div class="form-group">
              <label>{{item.key}}</label>
              <input type="text" id="campo_{{i}}" name="campo_{{i}}" placeholder="{{item.key}}" [(ngModel)]="item.value"
                class="form-control">
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-white" data-dismiss="modal">Fechar</button>
        <button type="button" class="btn btn-primary smartline__button"
          [ngStyle]="{'color':empresa?.textColor,'background-color':empresa?.bgColor,'border-color':empresa?.bgColor}"
          data-dismiss="modal" (click)="alterarSenha()">Salvar</button>
      </div>
    </div>
  </div>
</div>

<!--Modal EnviarMensagem -->
<div class="modal inmodal fade in" style="z-index: 9999 !important;" id="modalEnviarMensagem" tabindex="-1"
  role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content animated bounceInRight">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
            class="sr-only">Close</span></button>
        <h1><i class="glyphicon glyphicon-comment"></i></h1>
        <h4 class="modal-title">Enviar mensagem</h4>
        <p>Envie uma mensagem para senha <strong>{{senhaSelecionadaMensagem ? senhaSelecionadaMensagem?.senha :
            ''}}</strong>.
        </p>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <label class="smartline__label" htmlFor="descricaoMensagemSenha">Mensagem</label>
              <textarea class="form-control smartline__form" rows="6" placeholder="Observação..."
                name="descricaoMensagemSenha" [(ngModel)]="descricaoMensagemSenha" id="descricaoMensagemSenha">
                        </textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-white" data-dismiss="modal">Fechar</button>
        <button type="button" class="btn btn-primary smartline__button"
          [ngStyle]="{'color':empresa?.textColor,'background-color':empresa?.bgColor,'border-color':empresa?.bgColor}"
          data-dismiss="modal" (click)="enviarMensagemSenha()">Enviar Mensagem</button>
      </div>
    </div>
  </div>
</div>


<!--showModalExibirSenha-->
<div class="modal inmodal fade in" style="z-index: 0;" id="modalModalExibirSenha" data-backdrop="static" tabindex="-1"
  role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content animated bounceInRight">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
            class="sr-only">Close</span></button>
        <i class="fa fa-tablet modal-icon"></i>
        <h4 class="modal-title">Chamada</h4>
      </div>
      <div class="modal-body">
        <p class='text-center' style="font-size: 26px; font-weight: 700">
          {{senhaSelecionada && senhaSelecionada?.nome}}
        </p>

        <p class='text-center' style="font-size: 22px;">
          {{senhaSelecionada && senhaSelecionada?.senha}} - {{filaSelecionada ? filaSelecionada?.nome :
          senhaSelecionadaChamada?.fila ? senhaSelecionadaChamada?.fila?.nome : ''}}
        </p>
        <p class='text-center' style="font-size: 18px;">
          {{senhaSelecionada?.created_at && senhaSelecionada?.created_at | date : 'HH:mm:ss'}}
        </p>
        <p class='text-center' style="font-size: 18px">
          {{senhaSelecionada && senhaSelecionada?.descricao}}
        </p>
        <div class='flex justify-content-center align-items-center mt-3'>
          <a [href]="'tel:senhaSelecionada.numero'" class="btn btn-lg btn-success smartline__button mr-10"
            title="Ligar">
            <i class="glyphicon glyphicon-earphone"></i>
          </a>
          <button *ngIf="senhaSelecionada?.numero" type="button" (click)="selecionarSenhaMensagem(senhaSelecionada);"
            data-toggle="modal" data-target="#modalEnviarMensagem"
            class="btn btn-lg btn-primary smartline__button mr-10" title="Enviar Mensagem">
            <i class="glyphicon glyphicon-comment"></i>
          </button>
          <button class="btn btn-lg btn-primary smartline__button mr-5" [disabled]="loadingChamar || loadingRechamar"
            (click)="rechamarSenha()">
            <i class="glyphicon glyphicon-refresh"></i>
          </button>
        </div>
      </div>
      <div class="modal-footer">
        <div class='flex align-items-center justify-content-center'>
          <button type="button" class="btn btn-lg btn-font btn-danger btn--200" data-dismiss="modal"
            (click)="compareceuAtendimentoPager(false)">Não compareceu</button>
          <button type="button" class="btn btn-lg btn-font btn-success btn--200" data-dismiss="modal"
            (click)="compareceuAtendimentoPager(true)">Compareceu</button>
        </div>
      </div>
    </div>
  </div>
</div>