import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'lib-smart-core';
import { AcompanhamentoPainelComponent } from './modules/acompanhamento-painel/acompanhamento-painel.component';
import { AtendimentoHomeComponent } from './modules/atendimento-home/atendimento-home.component';
import { AtendimentoNovaSenhaComponent } from './modules/atendimento-nova-senha/atendimento-nova-senha.component';
import { AtendimentoPainelComponent } from './modules/atendimento-painel/atendimento-painel.component';
import { AtendimentoQrcodeFilasComponent } from './modules/atendimento-qrcode-filas/atendimento-qrcode-filas.component';
import { AtendimentoQrcodeFormComponent } from './modules/atendimento-qrcode-form/atendimento-qrcode-form.component';
import { LoginComponent } from './modules/login/login.component';
import { RedirectComponent } from './modules/redirect/redirect.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'redirect', component: RedirectComponent },
  { path: 'atendimento', component: AtendimentoHomeComponent, canActivate: [AuthGuard] },
  { path: 'atendimento/franquia', component: AtendimentoPainelComponent, canActivate: [AuthGuard] },
  { path: 'filas/:codigo', component: AtendimentoNovaSenhaComponent, canActivate: [AuthGuard] },
  { path: 'qrcode/:codigo', component: AtendimentoQrcodeFilasComponent, canActivate: [] },
  { path: 'qrcode-dados/:codigo/fila/:idFila/:preferencial', component: AtendimentoQrcodeFormComponent, canActivate: [] },
  { path: 'painel/:codigo/senha/:idSenha', component: AcompanhamentoPainelComponent, canActivate: [] },
  { path: '', redirectTo: '/atendimento', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
