import { Component, ElementRef, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';

import QRCode from 'qrcode';
import { BehaviorSubject, first, timer } from 'rxjs';

import { AbstractComponent, Chamada, Empresa, EmpresaService, Fila, FilaService, Franquia, Mesa, Pager, Senha, SenhaService, SocketService } from 'lib-smart-core';
import { Constantes } from '../../../environments/constantes';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-atendimento-painel',
  templateUrl: './atendimento-painel.component.html',
})
export class AtendimentoPainelComponent extends AbstractComponent implements OnInit, OnDestroy {

  idEmpresa: string;
  idFranquiaSelecionada: string;
  idMesaSelecionada: string;
  currentUser!: any;
  empresa: Empresa;
  franquia: Franquia;
  mesa: Mesa;
  filaSelecionada: Fila;
  MAP_TOTAL: Map<string, string> = new Map();
  filas: Fila[];
  geral: any;
  senhaSelecionada: Senha = {} as Senha;
  senhaSelecionadaEncaminhar: Senha = {} as Senha;
  senhaSelecionadaChamada: Senha = {} as Senha;
  senhaSelecionadaMensagem: Senha = {} as Senha;
  senhaSelecionadaEdicao: Senha = {} as Senha;
  modelSenhaJaChamada: Senha;
  previsaoAtendimentoMinutos: number = 0;
  descricaoMensagemSenha: string = '';
  loadingChamar: boolean = false;
  loadingRechamar: boolean = false;
  loadingEnviarMensagem: boolean = false;
  showModal: boolean = false;
  urlSenhaSelecionadaChamada: string = '';
  activePage: number = 1;
  historico: any[];
  viewHistorico: boolean = false;
  viewGeral: boolean = false;

  @ViewChild('btnModalQrcodeTitulo') btnModalQrcodeTitulo: ElementRef;

  nome: string = '';
  numero: string = '';
  descricao: string = '';

  @ViewChild('btnAtualizarChamada') btnAtualizarChamada: ElementRef;
  socket: any;
  socketConnected$: BehaviorSubject<boolean>;

  pager: Pager<Chamada> = new Pager<Chamada>({ page: 1, perPage: 5, total: 0 });
  @ViewChild('btnShowModalExibirSenha') btnShowModalExibirSenha: ElementRef;

  constructor(
    injector: Injector,
    private socketService: SocketService,
    private empresaService: EmpresaService,
    private filaService: FilaService,
    private senhaService: SenhaService) {
    super(injector);
  }

  ngOnInit() {

    const franquia = JSON.parse(localStorage.getItem(Constantes.currentFranquia));
    this.idFranquiaSelecionada = franquia._id;
    const mesa = JSON.parse(localStorage.getItem(Constantes.currentMesa));
    let currentUser = JSON.parse(localStorage.getItem(Constantes.currentUser));
    this.franquia = franquia;
    this.mesa = mesa;
    this.currentUser = currentUser;
    this.empresa = super.getEmpresaSession();

    this.carregarFilasPorFranquia(franquia._id);

    let idEmpresa = super.getIDEmpresaSession();
    if (idEmpresa) {
      this.idEmpresa = idEmpresa;
      this.loadEmpresa();
    }

    this.configSocket();
  }

  loadEmpresa = () => {
    this.empresaService.getPublic(this.idEmpresa).subscribe(
      (empresa) => {
        this.empresa = empresa;
      }
    );
  }

  carregarFilasPorFranquia(idFranquia) {
    this.filaService.getFilasPorFranquia(idFranquia).subscribe(
      filas => {
        const filaSelecionada = filas[0];
        this.filas = filas;
        this.filaSelecionada = filaSelecionada;
        this.carregarChamadaPorFila(filaSelecionada._id);
        this.atualizarListaChamada();
        this.configSocket();
      },
    );
  }

  carregarChamadaPorFila(idFila) {
    this.senhaService.getChamadaDiariaPorFilaPager(this.pager.page, this.pager.perPage, idFila).subscribe(
      (pager: Pager<Chamada>) => {
        this.pager = pager;
        this.setFilaSelcionada(idFila);
        this.carregarCountFilas();
        this.viewGeral = false;
        this.viewHistorico = false;
      },
    );
  }

  setFilaSelcionada(idFila) {
    this.filaService.getById(idFila).subscribe(
      fila => {
        this.filaSelecionada = fila;
      },
    );
  }

  carregarCountFilas() {
    this.filas.forEach(element => {
      this.senhaService.countChamadaPorFila(element._id).subscribe(count => this.MAP_TOTAL.set(element._id, count));
    });
  }

  atualizarListaChamada() {
    if (this.filaSelecionada) {
      this.pager.page = 1;
      this.carregarChamadaPorFila(this.filaSelecionada._id);
      this.carregarCountFilas();
      this.senhaService.previsaoAtendimento(this.franquia._id).subscribe(previsao => this.previsaoAtendimentoMinutos = previsao);
    } else {
      this.carregarCountFilas();
    }
  }

  /**
   * socket conection
   */
  configSocket(): void {
    this.socketService.connect();

    const sname = 'carregar_lista_' + this.franquia._id;
    this.socketService.listen(sname).subscribe(
      (data: any) => {
        this.btnAtualizarChamada.nativeElement.click();
      }
    )
  }

  handlePageChange(page) {
    this.pager.page = page;
    if (!this.viewHistorico && !this.viewGeral) {
      this.carregarChamadaPorFila(this.filaSelecionada._id)
    } else if (this.viewGeral) {
      this.carregarChamadaSenhaDiarioGeralPager(this.franquia._id)
    } else if (this.viewHistorico) {
      this.carregarHistoricoFranquia(this.franquia._id);
    }
  }

  temDadosListaChamadaCorrente() {
    let result = false;
    if (this.pager.list !== undefined && this.pager.list.length > 0) {
      result = true;
    }
    return result;
  }

  getTotalPorFila(idFila) {
    return this.MAP_TOTAL.get(idFila);
  }

  isFilaCurrent(idFila) {
    return this.filaSelecionada === null ? false : (idFila === this.filaSelecionada._id);
  }

  carregarSpinnerLoadingChamar() {
    this.loadingChamar = true;
    let subscription = timer(4500).pipe(first()).subscribe( // tempo necessario para tocar a musica na TV
      () => {
        this.loadingChamar = false;
        subscription.unsubscribe();
      }
    );
  }

  carregarSpinnerLoadingRechamar() {
    this.loadingRechamar = true;
    let subscription = timer(4500).pipe(first()).subscribe( // tempo necessario para tocar a musica na TV
      () => {
        this.loadingRechamar = false;
        subscription.unsubscribe();
      }
    );
  }

  chamarProximaSenha() {
    this.carregarSpinnerLoadingChamar();
    this.senhaService.chamarProximaSenha(this.filaSelecionada._id, this.mesa._id).subscribe(
      senha => {
        if (senha == null) {
          this.alertService.warning("Não existem senhas na fila " + this.filaSelecionada.nome.toUpperCase());
        }
        this.senhaSelecionada = senha;
        this.btnShowModalExibirSenha.nativeElement.click();
        this.atualizarListaChamada();
      },
    );
  }

  chamarSenhaNaChamada() {
    this.carregarSpinnerLoadingRechamar();
    this.senhaService.chamarSenhaNaChamadaPorId(this.mesa._id, this.senhaSelecionadaChamada._id).subscribe(
      senha => {
        this.senhaSelecionada = senha;
        this.btnShowModalExibirSenha.nativeElement.click();
        this.modelSenhaJaChamada = { senha: '' } as Senha;
        this.atualizarListaChamada();
        if (!!this.viewGeral && this.viewGeral === true) {
          this.carregarChamadaSenhaDiarioGeralPager(this.franquia._id)
        }
      },
    );
  }

  rechamarSenha() {
    this.carregarSpinnerLoadingRechamar();
    this.senhaService.rechamarSenha(this.senhaSelecionada._id).subscribe(() => { });
  }

  enviarMensagemSenha() {
    this.loadingEnviarMensagem = true;
    this.senhaService.enviarMensagemParaSenha(this.senhaSelecionadaMensagem._id, this.descricaoMensagemSenha).subscribe(
      senha => {
        this.senhaSelecionadaMensagem = null;
        this.descricaoMensagemSenha = '';
        this.loadingEnviarMensagem = false;
        this.atualizarListaChamada();
      },
    );
  }

  alterarSenha() {
    this.senhaSelecionadaEdicao['nome'] = this.nome;
    this.senhaSelecionadaEdicao['numero'] = this.numero;
    this.senhaSelecionadaEdicao['descricao'] = this.descricao;
    this.senhaSelecionadaEdicao['permiteWhatsApp'] = true;
    this.loadingEnviarMensagem = true;
    this.senhaService.update(this.senhaSelecionadaEdicao).subscribe(
      senha => {
        this.nome = '';
        this.numero = '';
        this.descricao = '';
        this.senhaSelecionadaEdicao = {} as Senha;
      },
    );
  }

  selecionarSenhaEncaminhar(senha) {
    this.senhaSelecionadaEncaminhar = senha;
  }

  selecionarSenhaNaChamada(senha) {
    this.senhaSelecionadaChamada = senha;
  }

  selecionarSenhaMensagem(senha) {
    this.senhaSelecionadaMensagem = senha;
  }

  selecionarSenhaEdicao(senha) {
    this.senhaSelecionadaEdicao = {} as Senha;
    this.nome = senha.nome;
    this.numero = senha.numero;
    this.descricao = senha.descricao;
    this.senhaSelecionadaEdicao = senha;
  }

  encaminhar(idFilaEncaminhar) {
    this.senhaService.encaminhar(idFilaEncaminhar, this.senhaSelecionadaEncaminhar._id, this.idFranquiaSelecionada).subscribe(
      senha => {
        this.senhaSelecionada = {} as Senha;
        this.senhaSelecionadaEncaminhar = {} as Senha;
        this.atualizarListaChamada();
      },
    );
  }

  selecionarSenhaNaChamadaQRCode(senha) {
    const urlSenhaSelecionadaChamada = `${environment.publicUrl}/painel/${this.franquia.codigo}/senha/${senha._id}/`;
    this.senhaSelecionadaChamada = senha;
    this.urlSenhaSelecionadaChamada = urlSenhaSelecionadaChamada;
    setTimeout(() => {
      QRCode.toCanvas(document.getElementById('canvas'), urlSenhaSelecionadaChamada, function (error) {
        if (error) console.error(error)
      })
    }, 200)

  }

  carregarChamadaSenhaDiarioGeralPager(idFranquia) {
    this.senhaService.getChamadaSenhaDiarioGeralPager(this.pager.page, this.pager.perPage, idFranquia).subscribe(
      (pager: Pager<Chamada>) => {
        this.pager = pager;
        this.filaSelecionada = null;
        this.viewGeral = true;
        this.viewHistorico = false;
      },
    );
  }

  carregarHistoricoFranquia(idFranquia) {
    this.senhaService.historicoChamadasFullDay(this.pager.page, this.pager.perPage, idFranquia).subscribe(
      (pager: Pager<Chamada>) => {
        this.pager = pager;
        this.filaSelecionada = null;
        this.viewGeral = false;
        this.viewHistorico = true;
      },
    );
  }

  compareceuAtendimentoPager(compareceu) {
    this.senhaService.compareceuAtendimentoPager(this.senhaSelecionada._id, compareceu).subscribe(
      () => {
      }
    );
  }

  loadPage(page: number) {
    this.pager.page = page;
    if (this.viewHistorico) {
      this.carregarHistoricoFranquia(this.franquia._id);
    } else {
      this.carregarChamadaPorFila(this.filaSelecionada._id);
    }
  }

}
