import { Component, ElementRef, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractComponent, Config, Empresa, EmpresaService, Fila, FilaService, Franquia, FranquiaService, Senha, SenhaService } from 'lib-smart-core';

@Component({
  selector: 'app-atendimento-nova-senha',
  templateUrl: './atendimento-nova-senha.component.html',
})
export class AtendimentoNovaSenhaComponent extends AbstractComponent implements OnInit, OnDestroy {

  codigo: string = '';
  idEmpresa: string = '';
  idFranquia: string = '';
  idFila: string = '';
  empresa: Empresa;
  franquia: Franquia;
  config: Config;
  filas: Fila[] = [];
  senhaSelecionada: Senha;
  filaSelecionada: Fila;
  nome: '';
  numero: '';
  descricao: '';
  loadingGerarSenha: boolean = false;
  loading: boolean = false;

  showNormalPreferencial: boolean = false;
  showDadosContatoFila: boolean = false;
  showConfirmarButton: boolean = false;
  dadosSenha: Senha;

  constructor(
    injector: Injector,
    private franquiaService: FranquiaService,
    private empresaService: EmpresaService,
    private filaService: FilaService,
    private senhaService: SenhaService) {
    super(injector);
  }

  ngOnInit(): void {
    let codigo = super.getParam('codigo');
    this.loadFranquiaPorCodigo(codigo);
    this.dadosSenha = {} as Senha;
  }

  loadFranquiaPorCodigo = (codigo) => {
    this.franquiaService.getFranquiaPorCodigo(codigo).subscribe(
      (franquia: Franquia) => {
        this.franquia = franquia;
        this.idFranquia = franquia._id;
        this.idEmpresa = franquia.empresa._id;

        this.loadEmpresa();
        this.loadBotoes();
      }
    );
  }

  loadEmpresa = () => {
    this.empresaService.getPublic(this.idEmpresa).subscribe(
      (empresa: Empresa) => {
        this.empresa = empresa;
      }
    );
  }

  loadBotoes = () => {
    this.filaService.getFilasPorFranquia(this.idFranquia).subscribe(
      (filas: Fila[]) => {
        this.filas = filas;
      }
    );
  }

  pagerBack() {
    this.router.navigate(['atendimento/franquia'])
  }

  selecionarFila(fila: Fila) {
    this.filaSelecionada = fila;
    if (fila.preferencial === true) {
      this.showNormalPreferencial = true;
    } else if (fila.dadosContato === true) {
      this.showDadosContatoFila = true;
      this.showConfirmarButton = true;
    } else {
      this.gerarNovaSenhaMetadata();
    }
  }

  selecionarNormalPreferencial(preferencial: boolean) {
    this.dadosSenha.preferencial = preferencial;
    if (this.filaSelecionada.dadosContato === true) {
      this.showDadosContatoFila = true;
      this.showConfirmarButton = true;
    } else {
      this.gerarNovaSenhaMetadata();
    }
  }

  gerarNovaSenhaMetadata() {
    this.loading = true;
    const idFila = this.filaSelecionada._id;
    const metadata = {
      nome: this.nome,
      numero: this.numero,
      descricao: this.descricao,
      permiteWhatsApp: true,
      app: 'PAGER',
      campos: []
    }

    if (!!this.showDadosContatoFila && this.showDadosContatoFila) {
      if (!!this.filaSelecionada.campos && this.filaSelecionada.campos.length > 0) {
        this.filaSelecionada.campos.forEach((campo: any, index: number) => {
          const newCampoValue = {
            key: campo.key,
            value: (document.getElementById('campo_' + index) as any).value
          }
          console.log()
          metadata.campos.push(newCampoValue);
        });
      }
    }

    if (this.dadosSenha.preferencial === true) {
      this.senhaService.gerarNovaSenhaPreferencialMetadata(idFila, this.dadosSenha.preferencial, metadata).subscribe({
        next: (senha: Senha) => {
          this.senhaSelecionada = senha;
          this.router.navigate(['atendimento/franquia'])
        },
        error: (err) => this.alertService.error(err.error.message),
        complete: () => this.loading = false
      });
    } else {
      this.senhaService.gerarNovaSenhaMetadata(idFila, metadata).subscribe({
        next: (senha: Senha) => {
          this.senhaSelecionada = senha;
          this.router.navigate(['atendimento/franquia'])
        },
        error: (err) => this.alertService.error(err.error.message),
        complete: () => this.loading = false
      });
    }
  }

}
