import { Component, ElementRef, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractComponent, Config, Empresa, Fila, Franquia, PublicConfigService, PublicEmpresaService, PublicFilaService, PublicFranquiaService, PublicSenhaService, Senha } from 'lib-smart-core';

@Component({
  selector: 'app-atendimento-qrcode-filas',
  templateUrl: './atendimento-qrcode-filas.component.html',
})
export class AtendimentoQrcodeFilasComponent extends AbstractComponent implements OnInit, OnDestroy {

  @ViewChild('btnCloseSelectNormalPreferencial') btnCloseSelectNormalPreferencial: ElementRef;

  senhaSelecionada: Senha;
  filaSelecionada: Fila;

  idEmpresa: string;
  idFranquia: string;

  empresa: Empresa;
  franquia: Franquia;
  config: Config;
  filas: Fila[];
  loading: boolean = false;

  constructor(
    private configService: PublicConfigService,
    private filaService: PublicFilaService,
    private franquiaService: PublicFranquiaService,
    private senhaService: PublicSenhaService,
    private empresaService: PublicEmpresaService,
    injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit() {
    let codigo = super.getParam('codigo');
    if (codigo) {
      this.loadFranquiaPorCodigo(codigo);
    }
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.btnCloseSelectNormalPreferencial.nativeElement.click();
  }

  loadFranquiaPorCodigo(codigo: string) {
    this.franquiaService.getFranquiaPorCodigo(codigo).subscribe(
      (franquia: Franquia) => {
        this.franquia = franquia;
        this.idFranquia = this.franquia._id;
        this.idEmpresa = this.franquia.empresa._id;
        this.loadEmpresa();
        this.loadFranquia();
      }, err => this.alertService.error(err.error.message)
    );
  }

  loadFranquia() {
    this.franquiaService.getById(this.idFranquia).subscribe(
      (franquia: Franquia) => {
        this.franquia = franquia;
        this.loadConfig();
        this.loadBotoes();
      }, err => this.alertService.error(err.error.message)
    );
  }

  loadEmpresa() {
    this.empresaService.getPublic(this.idEmpresa).subscribe(
      (empresa: Empresa) => {
        this.empresa = empresa;
      }, err => this.alertService.error(err.error.message)
    );
  }

  loadConfig() {
    this.configService.getConfigPorFranquia(this.idFranquia).subscribe(
      (config: Config) => {
        this.config = config;
      }, err => this.alertService.error(err.error.message)
    );
  }

  loadBotoes() {
    this.filaService.getFilasPorFranquia(this.idFranquia).subscribe(
      (filas: Fila[]) => {
        this.filas = filas;
      }, err => this.alertService.error(err.error.message)
    );
  }

  gerarNovaSenha(fila: Fila, preferencial = false) {

    if (fila.dadosContato) {
      this.router.navigate(['qrcode-dados', this.franquia.codigo, 'fila', fila._id, preferencial]);
    } else {
      this.loading = true;
      this.senhaService.gerarNovaSenhaMetadata(fila._id, { app: 'PAGER' }, preferencial).subscribe(
        // this.senhaService.gerarNovaSenha(fila._id, preferencial).subscribe(
        (senha: Senha) => {
          this.loading = false;
          this.senhaSelecionada = senha;
          this.filaSelecionada = fila;

          this.router.navigate(['painel', this.franquia.codigo, 'senha', senha._id]);
        }, err => {
          this.loading = false;
          this.alertService.error(err.error.message)
        }
      );
    }
  }

  selecionar(fila: Fila) {
    this.filaSelecionada = fila;
  }

}
